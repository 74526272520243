

























import { Vue, Component } from 'vue-property-decorator';
import DashboardApi from '@/services/dashboard';
import { DashboardConfigurationRequest } from '@/models/dashboard';

@Component({
  components: {},
})
export default class CreateDashboard extends Vue {
  dashboardApi = new DashboardApi();

  dashboardName = '';

  dashboardDescription = '';

  dashboardIcon = '';

  isDashboardPublic = false;

  dashboard: DashboardConfigurationRequest = {
    name: '',
    description: '',
    icon: '',
    iconBgColor: '',
    iconColorType: 'light',
    isPublic: false,
  }

  onCreateDashboard(): void {
    this.dashboardApi.create(this.dashboard).then((response) => {
      if (response.success) {
        this.$router.push('/');
      } else {
        console.log('err', response);
      }
    });
  }
}
